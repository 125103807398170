export function createSky(scene, nightMode = false) {
    const graphics = scene.add.graphics();
    //const width = scene.cameras.main.width;
    const worldWidth = scene.cameras.main._bounds.width;
    const height = scene.cameras.main.height;

    // Define the gradient colors and positions
    if (nightMode) {
        graphics.fillGradientStyle(
            0x0d1b2a, // Top color (deep navy blue)
            0x0d1b2a, // Top color (deep navy blue)
            0x1b263b, // Middle color (dark blue/purple)
            0x415a77, // Bottom color (darker blue)
            1 // Alpha
        );
    } else {
        graphics.fillGradientStyle(
            0x8fd3ff, // Top color (lighter blue)
            0x8fd3ff, // Top color (lighter blue)
            0x3b5998, // Bottom color (darker blue)
            0x3b5998, // Bottom color (darker blue)
            1 // Alpha
        );
    }

    // Draw the gradient rectangle
    graphics.fillRect(0, 0, worldWidth, height);

    // Set the graphics object behind other game objects
    graphics.setDepth(-1)
}

export function createStars(scene, numStars) {
    for (let i = 0; i < numStars; i++) {
        let x = Phaser.Math.Between(0, scene.cameras.main.width);
        let y = Phaser.Math.Between(0, scene.cameras.main.height / 2 + 100); // Keep stars in the upper half
        let star = scene.add.circle(x, y, 1, 0xffffff); // Small white circles as stars
        star.setAlpha(Phaser.Math.FloatBetween(0.3, 1)); // Randomize star brightness
        star.setScrollFactor(0); // Stars stay fixed relative to the camera
    }
}

export function createMoon(scene) {
    createGlowTexture(scene); // Ensure the glow texture is created
    // Create the moon as a base object
    const moon = scene.add.circle(scene.cameras.main.width - 100, 100, 40, 0xffffcc);
    moon.setAlpha(0.8); // Slight transparency for realism
    moon.setScrollFactor(0); // Moon stays fixed relative to the camera
    moon.setPipeline('Light2D'); // Enable 2D lighting for the moon
}


function createGlowTexture(scene) {
    const glow = scene.add.graphics();
    glow.fillStyle(0xffffcc, 0.6); // Yellowish white color for the glow
    glow.fillCircle(10, 10, 10); // Draw a small circle
    glow.generateTexture('glowParticle', 20, 20); // Generate a texture from the circle
    glow.destroy(); // Clean up the graphics object
}
