export function createTrees(scene, background = false) {
    let treeTextures = [
        'Tree_large.png',
        'Tree_medium.png'
    ];
    if (background) {
        treeTextures = [
            'Tree_medium_background.png',
            'Tree_medium_background_2.png'
        ];
    }
    const treeSpacing = background ? 17 : 50; // Set the spacing between trees
    const worldWidth = scene.cameras.main._bounds.width;
    const scrollFactor = background ? 0.6 : 0.8;

    for (let x = 0; x < worldWidth; x += treeSpacing) {
        const treeTexture = Phaser.Math.RND.pick(treeTextures); // Randomly pick a tree texture
        const tree = scene.add.sprite(x, scene.cameras.main.height, 'backgrounds', treeTexture)
            .setOrigin(0, 1)
            .setScrollFactor(scrollFactor, 0)
            .setPipeline('Light2D');

    }
}
