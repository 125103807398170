// src/utils/fence.js
export function installFence(scene, length, startX) {
    // Get fence textures
    const fenceEndFrame = scene.textures.getFrame('backgrounds', 'Fence_end.png');
    const fenceConnectingFrame = scene.textures.getFrame('backgrounds', 'Fence_connecting.png');

    // Get widths
    const fenceEndWidth = fenceEndFrame.width;
    const fenceConnectingWidth = fenceConnectingFrame.width;
    const scrollFactor = 1;

    // Install starting fence end
    scene.add.sprite(startX, scene.cameras.main.height, 'backgrounds', 'Fence_end.png').setOrigin(0, 1)
        .setScrollFactor(scrollFactor, 0)
        .setPipeline('Light2D');

    // Install connecting fences
    for (let i = 0; i < length; i++) {
        const posX = startX + fenceEndWidth + (fenceConnectingWidth * i);
        scene.add.sprite(posX, scene.cameras.main.height, 'backgrounds', 'Fence_connecting.png').setOrigin(0, 1)
            .setScrollFactor(scrollFactor, 0)
            .setPipeline('Light2D');
    }

    // Install ending fence end flipped horizontally
    const endPosX = startX + fenceEndWidth + (fenceConnectingWidth * length);
    scene.add.sprite(endPosX, scene.cameras.main.height, 'backgrounds', 'Fence_end.png')
        .setOrigin(0, 1)
        .setFlipX(true)
        .setScrollFactor(scrollFactor, 0)
        .setPipeline('Light2D');
}
