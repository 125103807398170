// src/scenes/MainMenu.js
import Phaser from 'phaser';
import CloudManager from '@/objects/CloudManager';
import { installFence } from '@/utils/fence';
import { buildMoutains } from '@/utils/mountain';
import { createSky, createStars, createMoon } from '@/utils/createSky';
import { createTrees } from '@/utils/createTrees';
import { createHouses } from '@/utils/createHouses';
import { createRain, createNightEffect, createFogEffect } from '@/utils/environment';
import { createAnimations } from '@/animations/animations';

export default class MainScene extends Phaser.Scene {
    constructor() {
        super('MainScene');
    }

    create() {
        const worldWidth = 5000; // Example: 5000 pixels wide
        const worldHeight = this.cameras.main.height;
        this.cameras.main.setBounds(0, 0, worldWidth, worldHeight); // Set the world bounds to be larger than the visible area
        createAnimations(this);

        createSky(this, true);
        createStars(this, 100); // 100 stars
        createMoon(this); // Add a moon

        const cloudManager = new CloudManager(this);
        cloudManager.createInitialClouds(Math.floor(worldWidth / 50));
        cloudManager.startCloudGeneration(4000); // Adjust the interval as needed
        buildMoutains(this);
        createTrees(this, true);
        createHouses(this);
        createTrees(this);

        installFence(this, 10, 0);
        installFence(this, 50, 400);

        this.cursors = this.input.keyboard.createCursorKeys();
        createRain(this);

        createFogEffect(this);
        createNightEffect(this);
        this.createElk();
    }

    update() {
        const camera = this.cameras.main;
        const scrollSpeed = 1;

        if (this.cursors.left.isDown) {
            camera.scrollX -= scrollSpeed;
        } else if (this.cursors.right.isDown) {
            camera.scrollX += scrollSpeed;
        }

        // if elk is walking, move it
        if (this.elk.anims.currentAnim.key === 'elk-walk') {
            this.elk.x += 1;
        }

    }

    createElk() {
        this.elk = this.add.sprite(300, this.cameras.main.height, 'elk', 'Elk Sprite Sheet 1.aseprite')
            .setOrigin(0, 1)
            .setScale(1)
            .play('elk-eat');


        this.time.delayedCall(10000, () => {
            this.elk.play('elk-look-up');
            // after animation end play walk animation
            this.elk.on('animationcomplete', (animation) => {
                if (animation.key === 'elk-look-up') {
                    this.elk.play('elk-walk');
                }
                const elkGlow = this.add.sprite(this.elk.x, this.elk.y, 'elk').play('elk-glow');
                elkGlow.setBlendMode(Phaser.BlendModes.ADD);
                elkGlow.setAlpha(.6); // Adjust transparency for a subtle glow
                elkGlow.setDepth(100); // Ensure the glow is on top
                elkGlow.setOrigin(0, 1);

                // Sync positions to follow the elk
                this.events.on('update', () => {
                    elkGlow.x = this.elk.x;
                    elkGlow.y = this.elk.y;
                });

                // add light
                const light = this.lights.addLight(this.elk.x, this.elk.y, 30)
                    // Set bluewhite color
                    .setColor(0xaaf3ff)
                    .setIntensity(2);

                // Sync light position with elk
                this.events.on('update', () => {
                    light.x = this.elk.x + 33
                    light.y = this.elk.y - 22
                });
            });

            return this.elk;
        });
    }
}
