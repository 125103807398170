// src/GameConfig.js
import Preloader from '@/scenes/Preloader'; // Import the Preloader
import MainScene from '@/scenes/MainScene'; // Import the MainScene

// const screenResolutions = [
//     { width: 320, height: 180 },
//     { width: 640, height: 360 },
//     { width: 854, height: 480 },
//     { width: 1024, height: 576 },
//     { width: 1280, height: 720 },
//     { width: 1366, height: 768 },
//     { width: 1600, height: 900 },
//     { width: 1920, height: 1080 },
//     { width: 2560, height: 1440 },
//     { width: 3840, height: 2160 },
//     { width: 7680, height: 4320 },
// ];


const screenResolutions = [
    { width: 320, height: 180 }
];

const screenResolution = screenResolutions[0];

export const config = {
    type: Phaser.AUTO,
    width: screenResolution.width,
    height: screenResolution.height,
    backgroundColor: '#8fd3ff',
    pixelArt: true,
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        width: screenResolution.width,
        height: screenResolution.height
    },
    physics: {
        default: 'arcade',
        arcade: {
            gravity: { y: 200 },
            debug: false
        }
    },
    scene: [Preloader, MainScene]
};
