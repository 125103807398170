export function buildMoutains(scene) {
    const textures = [
        'Mountain_large_1.png',
        'Mountain_large_2.png',
        'Mountain_medium_1.png',
        'Mountain_medium_2.png',
        'Mountain_small_1.png',
        'Mountain_small_2.png'
    ];

    const spacing = 150;
    const worldWidth = scene.cameras.main._bounds.width;

    for (let x = 0; x < worldWidth; x += spacing) {
        const texture = Phaser.Math.RND.pick(textures); // Randomly pick a tree texture
        const mountain = scene.add.sprite(x, scene.cameras.main.height, 'backgrounds', texture)
            .setOrigin(0, 1)
            .setScrollFactor(.3, 0);
        mountain.setPipeline('Light2D');
    }
}