export function createAnimations(scene) {
    // Player animations
    scene.anims.create({
        key: 'elk-eat',
        frames: scene.anims.generateFrameNames('elk', {
            start: 1,
            end: 6,
            prefix: 'Elk Sprite Sheet ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'elk-look-up',
        frames: scene.anims.generateFrameNames('elk', {
            start: 7,
            end: 25,
            prefix: 'Elk Sprite Sheet ',
            suffix: '.aseprite'
        }),
        frameRate: 5,
        repeat: 0
    });

    scene.anims.create({
        key: 'elk-walk',
        frames: scene.anims.generateFrameNames('elk', {
            start: 26,
            end: 33,
            prefix: 'Elk Sprite Sheet ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });

    scene.anims.create({
        key: 'elk-glow',
        frames: scene.anims.generateFrameNames('elk', {
            start: 26,
            end: 33,
            prefix: 'Elk Sprite Sheet ',
            suffix: '.aseprite'
        }),
        frameRate: 10,
        repeat: -1
    });
}