import backgroundsImg from '@assets/backgrounds.png';
import backgroundsJson from '@assets/backgrounds.json';
import elkImg from '@assets/animals/elk.png';
import elkJson from '@assets/animals/elk.json';
// import playerImg from '@assets/players/player.png';
// import playerJson from '@assets/players/player.json';
// import explosionMp3 from '@assets/audio/explosion.mp3';

export function loadAssets(scene) {
    scene.load.atlas('backgrounds', backgroundsImg, backgroundsJson);
    scene.load.atlas('elk', elkImg, elkJson);
    // scene.load.tilemapTiledJSON('backgrounds', preloadJson);
    // scene.load.image('tiles', tileImg);
    // scene.load.audio('bombExplosion', explosionMp3);
}
