// src/objects/CloudManager.js
import Phaser from 'phaser';

export default class CloudManager {
    constructor(scene) {
        this.scene = scene;

        const raindrop = this.scene.add.graphics();
        raindrop.lineStyle(2, 0x9ecae1, 1); // Thin blue line for the raindrop
        raindrop.beginPath();
        raindrop.moveTo(0, 0);
        raindrop.lineTo(0, 10); // Length of the raindrop
        raindrop.strokePath();
        raindrop.closePath();
        raindrop.generateTexture('rainDropTexture', 2, 10); // Create a texture from the graphic
        raindrop.destroy();

        this.particles = this.scene.add.particles('rainDropTexture');
    }

    createInitialClouds(count) {
        for (let i = 0; i < count; i++) {
            this.createRandomCloud(true);
        }
    }

    startCloudGeneration(interval) {
        this.scene.time.addEvent({
            delay: interval, // Interval between cloud spawns
            callback: () => this.createRandomCloud(),
            loop: true,
        });
    }

    createRandomCloud(isInitial = false) {
        const textures = ['Cloud_1.png', 'Cloud_2.png', 'Cloud_3.png', 'Cloud_small_1.png', 'Cloud_small_2.png'];
        const texture = Phaser.Math.RND.pick(textures);
        const yPosition = Phaser.Math.Between(10, this.scene.cameras.main.height / 3);
        const speed = Phaser.Math.Between(3, 5);
        const alpha = Phaser.Math.FloatBetween(0.5, 1);

        const xPosition = isInitial
            ? Phaser.Math.Between(0, this.scene.cameras.main._bounds.width)
            : this.scene.cameras.main._bounds.width + 100;

        const cloud = this.scene.add.sprite(
            xPosition,
            yPosition,
            'backgrounds',
            texture
        )
            .setOrigin(0, 0)
            .setAlpha(alpha)
            .setScrollFactor(speed / 10, 0);

        this.scene.tweens.add({
            targets: cloud,
            x: -cloud.width,
            duration: (this.scene.cameras.main._bounds.width + cloud.width) / speed * 1000,
            repeat: 0,
            yoyo: false,
            onComplete: () => {
                cloud.destroy();
            }
        });
    }
}
