export function createHouses(scene) {
    const houseTextures = [
        // 'House_small_beige.png',
        // 'House_small_blue.png',
        // 'House_small_green.png',
        // 'House_small_black.png',
        // 'House_small_purple.png',
        // 'House_small_red.png',
        // 'House_small_yellow.png',
        // 'House_large_beige.png',
        // 'House_large_blue.png',
        // 'House_large_green.png',
        'House_large_black.png',
        // 'House_large_purple.png',
        // 'House_large_red.png',
        // 'House_large_yellow.png'
    ];

    const spacing = 500;
    const worldWidth = scene.cameras.main._bounds.width;

    scene.lights.enable().setAmbientColor(0x555555);


    for (let x = 40; x < worldWidth; x += spacing) {
        const houseTexture = Phaser.Math.RND.pick(houseTextures); // Randomly pick a tree texture
        const house = scene.add.sprite(x, scene.cameras.main.height, 'backgrounds', houseTexture)
            .setOrigin(0, 1)
            .setScrollFactor(.7, 0);

        house.setPipeline('Light2D');
        const light = scene.lights.addLight(house.x + 15, house.y - 5, 20)
            .setColor(0xFFFF00)
            .setIntensity(2)
            .setRadius(50);
        //.setScrollFactor(.7, 0);

        // follow house
        light.setScrollFactor(.7, 0);

    }
}
