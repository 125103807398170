// src/scenes/Preloader.js
import Phaser from 'phaser';
import { loadAssets } from '@/utils/AssetLoader';

export default class Preloader extends Phaser.Scene {
    constructor() {
        super('Preloader');
    }

    preload() {
        // Load all the game assets
        loadAssets(this);

        // Optional: Display loading progress
        const progressBar = this.add.graphics();
        const progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(240, 270, 320, 50);

        const loadingText = this.make.text({
            x: this.cameras.main.width / 2,
            y: this.cameras.main.height / 2 - 50,
            text: 'Loading...',
            style: {
                font: '20px monospace',
                fill: '#ffffff'
            }
        }).setOrigin(0.5);

        this.load.on('progress', (value) => {
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(250, 280, 300 * value, 30);
        });

        this.load.on('complete', () => {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
        });
    }

    create() {
        this.scene.start('MainScene');

        //
        const playButton = this.add.text(this.cameras.main.width / 2, this.cameras.main.height / 2, 'Play', {
            fontSize: '24px',
            fill: '#000'
        }).setOrigin(0.5).setInteractive();

        playButton.on('pointerup', () => {
            this.handlePlayButton();
        });
    }

    handlePlayButton() {
        this.scene.start('MainScene');
    }
}
