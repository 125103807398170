export function createNightEffect(scene) {
    const nightOverlay = scene.add.graphics();
    nightOverlay.fillStyle(0x000000, 0.5); // Black color with 50% opacity
    nightOverlay.fillRect(0, 0, scene.cameras.main.width, scene.cameras.main.height);
    nightOverlay.setScrollFactor(0); // Ensure the overlay moves with the camera
    nightOverlay.setDepth(10); // Ensure it covers all other objects
}

export function createFogEffect(scene) {
    const fogTexture = scene.add.graphics();
    fogTexture.fillStyle(0xaaaaaa, .2); // Light gray with 20% opacity
    fogTexture.fillRect(0, 0, 10, 10); // Create a small fog patch
    fogTexture.generateTexture('fogTexture', 10, 10);
    fogTexture.destroy();

    const emitter = scene.add.particles(0, scene.cameras.main.height / 2 + 50, 'fogTexture', {
        x: { min: 0, max: scene.cameras.main._bounds.width }, // Spread fog across the screen width
        y: { min: 0, max: scene.cameras.main.height / 2 }, // Fog is generally closer to the ground
        lifespan: 5000, // Fog particles live for 10 seconds
        speedX: { min: 5, max: 20 }, // Slow horizontal movement
        speedY: { min: -1, max: 1 }, // Gentle vertical drift
        scale: { start: 0.5, end: 1.5 }, // Particles grow slightly as they drift
        quantity: 10, // Few particles at a time for a subtle effect
        blendMode: 'NORMAL', // Blend with the background
    });
}

export function createRain(scene) {
    // Create a small rectangle to simulate a raindrop
    const raindrop = scene.add.graphics();
    raindrop.fillStyle(0x9ecae1, 1); // Blue color
    raindrop.fillRect(0, 0, 2, 10); // A simple rectangle for the raindrop
    raindrop.generateTexture('rainDropTexture', 2, 10); // Convert to texture
    raindrop.destroy(); // Destroy the graphics object

    // Create an emitter that simulates the rain
    const emmiter = scene.add.particles(0, 0, 'rainDropTexture', {
        x: { min: 0, max: scene.cameras.main.width }, // Rain falls across the screen width
        y: 0, // Start at the top of the screen
        lifespan: 2000, // Each raindrop lives for 2 seconds
        speedY: { min: 200, max: 300 }, // Falling speed of the raindrops
        scale: { start: 0.1, end: 0.1 }, // Constant size
        quantity: 10, // Number of raindrops per emission
        blendMode: 'NORMAL',
        on: true // Continuous emission
    });

    emmiter.setScrollFactor(0);
}
